body {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
  filter: invert(100%);
  /*filter: invert(8%) sepia(100%) saturate(6481%) hue-rotate(246deg) brightness(102%) contrast(143%);*/
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header ul, ol {
  align-items: left;
  text-align: left;
  font-size: calc(10px + 1vmin);
  max-width: 50%;
}

.App-header ul {
  list-style: none; /* Remove default bullets */
}

.App-header ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #04AA6D; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.App-header ol {
  list-style: none; 
  counter-reset: li;
}

.App-header ol li::before {
  content: counter(li) ". "; 
  color: #04AA6D;
  display: inline-block; 
  width: 1.5em;
  margin-left: -1.5em;
}

.App-header ol li {
  counter-increment: li
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-size: calc(10px + 1vmin);
}

h2 {
  margin-bottom: 0.5em;
}

h3 {
  margin-top: 5px;
}

h4 {
  margin-bottom: 5px;
  color: #04AA6D;
}

.Tagline {
  font-size: calc(10px + 1vmin);
  font-style: italic;
  color: #04AA6D;
}

.Disclaimer {
  font-size: calc(10px + 1vmin);
  font-style: italic;
  color: #04AA6D;
  max-width: 50%;
}

.RecipeImg {
  border: 10px solid transparent;
  border-image: url("./border.png") 30 stretch;
}

.nav a {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
}

@media(hover) {
  .nav a:hover {
    background-color: #ccc;
    color: black;
  }
}

.nav .previous {
  background-color: #f1f1f1;
  color: black;
}

.nav .next {
  background-color: #04AA6D;
  color: white;
}

.Categories a {
  font-size: calc(10px + 1vmin);
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  margin-left: 1px;
  margin-top: 1em;
}

.Categories a:first-child {
  border-top-left-radius: 15%;
  border-bottom-left-radius: 15%;
}

.Categories a:last-child {
  border-top-right-radius: 15%;
  border-bottom-right-radius: 15%;
}

.Categories a img {
  height: 1.5em;
}

.CategorySelected {
  background-color: #04AA6D;
  color: white;
}

.CategoryNotSelected {
  background-color: #f1f1f1;
  color: black;
}

@media(hover) {
  .CategoryNotSelected:hover {
    background-color: #ccc;
    color: black;
  }
}